.player {
  padding: 10px 12px;
  background-color: #dfdfdf;
  border-radius: 10px;
  gap: 0 12px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  @include mdq-max() {
    margin-bottom: 20px;
  }

  &-thumb {
    position: relative;
    flex: 0 0 50px;
    width: 50px;
    overflow: hidden;
    border-radius: 5px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      max-width: none;
      max-height: none;
      min-width: 1000%;
      min-height: 1000%;
      transform: translate(-50%, -50%) scale(0.1);
      display: block;
    }

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }

  &-info {
    flex: 1 1 1%;
    width: 1%;

    &-title {
      color: #333;
      font-size: 15px;

      mark {
        color: #333;
      }
    }

    &-name {
      color: #828284;
      font-size: 12px;
      margin-top: 4px;

      mark {
        color: #828284;
      }
    }
  }

  &-buttons {
    display: flex;
    align-items: center;
    gap: 0 8px;

    .btn-play,
    .btn-next {
      position: relative;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 0;
      background: none;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .btn-play {
      .icon-pause {
        display: none;
      }

      &.playing {
        .icon-play {
          display: none;
        }

        .icon-pause {
          display: block;
        }
      }
    }
  }
}