.message-popup {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 68%;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  z-index: 3;

  .title {
    font-size: 17px;
    line-height: calc(65/50);
    font-weight: 700;
  }

  .text {
    font-size: 15px;
    line-height: calc(55/46);
    margin-top: 2px;
  }

  &-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 0 8px;

    .btn {
      color: #000;
      background-color: #f8795a;
      border-radius: 50px;
      text-align: center;
      font-weight: 700;
      font-size: 15px;
      padding: 6px 0;
      flex: 1 1 50%;
      border: 0;
      cursor: pointer;

      span {
        display: block;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
}

.toast-message {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 68%;
  text-align: center;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  z-index: 3;
  animation: toastAnimation 0.3s linear 0s 1 forwards;

  @keyframes toastAnimation {
    0% {
      opacity: 0;
      top: 46%;
    }

    100% {
      opacity: 1;
      top: 45%;
    }
  }

  .title {
    font-size: 17px;
    line-height: calc(65/50);
    font-weight: 700;
  }

  .text {
    font-size: 15px;
    line-height: calc(55/46);
    margin-top: 2px;
  }
}