.btn-has-effect {
  position: relative;
  //box-shadow: 0 2px #999;

  img {
    z-index: 1;
  }

  &:active {
    box-shadow: 0 0 #666;
    transform: translateY(1px);
  }

  /* wave effect

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 0;
  }

  &.clicked::after {
    animation: btnEffect 1s linear 0s 1 forwards;
  }

  @keyframes btnEffect {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
    }

    50% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1.2);
    }

    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.2);
    }
  }*/
}