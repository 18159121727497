.member-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 30px;

  .item {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;

    @include mdq-max {
      border-radius: 20px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      max-width: none;
      max-height: none;
      min-width: 1000%;
      min-height: 1000%;
      transform: translate(-50%, -50%) scale(0.1);
    }

    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
  }
}