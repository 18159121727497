@charset "UTF-8";

@mixin mdq-max {
  @media all and (max-width: 768px) {
    @content;
  }
}

@import "layout";
@import "buttons";
@import "player";
@import "main";
@import "list";
@import "video";
@import "popup";