mark {
  background-color: #fad3d3;
}

a {
  text-decoration: none;
  color: #000000;
}

.content {
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000 url(/imgs/common/main-bg-text.png) top 10px center/100% no-repeat;

  * {
    box-sizing: border-box;
  }

  @include mdq-max() {
    background: #000;
  }
}

.sub-header {
  position: relative;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn-wrap {
    display: flex;
    align-items: center;
    gap: 0 10px;
  }

  .btn-home {
    color: #000;
    background: none;
    border: 0;
    padding: 0;
    cursor: pointer;

    img {
      display: block;
      height: 30px;
    }

    //&::before {
    //  content: '';
    //  display: block;
    //  flex: 0 0 9px;
    //  width: 9px;
    //  height: 9px;
    //  background: url(/imgs/common/angle-left.png) center/100% no-repeat;
    //}
  }

  .btn-play {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #fff;
    cursor: pointer;
    padding: 0;
    
    &.stopped::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 1px;
      height: 100%;
      background-color: #f00;
    }
  }

  .btn-download {
    color: #000;
    cursor: pointer;

    img {
      display: block;
      height: 30px;
    }
  }

  .camera {
    position: absolute;
    top: -34px;
    left: 50%;
    transform: translateX(-50%);
    width: 94px;

    @include mdq-max {
      display: none;
    }
  }
}

.status-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-left: 20px;

  .clock {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
  }

  img {
    display: block;
    flex: 0 0 72px;
    width: 72px;
  }

  &.dark .clock {
    color: #000;
  }
}

.mockup {
  position: relative;
  width: 360px;
  height: 735px;
  margin: 0 auto;
  padding: 14px 17px 15px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/imgs/common/mockup.png) center/100% no-repeat;
    z-index: 1;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 20px);
    height: calc(100% - 16px);
    background-color: #000;
    border-radius: 48px;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 2;
    padding: 16px 18px;
    width: 100%;
    height: 100%;
    border-radius: 41px;
    overflow: hidden;
  }

  &-content {
    height: calc(100% - 123px);
    padding: 0 25px;
    margin: 0 -25px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px; /* 스크롤바의 너비 */
    }

    &::-webkit-scrollbar-track {
      background: rgba(#fff, 0.7); /* 트랙의 배경색 */
      border-radius: 10px; /* 트랙의 둥근 모서리 */
    }

    &::-webkit-scrollbar-thumb {
      background: #333; /* 핸들의 색상 */
      border-radius: 10px; /* 핸들의 둥근 모서리 */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #222; /* 호버 상태일 때 핸들의 색상 */
    }

  }

  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    img {
      display: block;
    }

    .footer-group {
      position: absolute;
      bottom: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      width: 100%;
      gap: 0 10px;
      color: #747473;
      
      a {
        color: #747473;
      }
    }
  }

  &.video {
    .mockup-inner {
      padding: 0;
    }

    .mockup-content {
      height: calc(100% - 110px);

      @include mdq-max {
        height: auto;
      }
    }

    .status-bar {
      position: absolute;
      top: 16px;
      left: 0;
      width: 100%;
      padding-left: 38px;
      padding-right: 18px;
      z-index: 2;
    }

    .sub-header {
      position: absolute;
      top: 44px;
      left: 0;
      z-index: 2;
      width: 100%;
      padding-left: 18px;
      padding-right: 18px;

      @include mdq-max {
        position: relative;
        padding: 0;
        top: 0;
      }
    }
  }

  .popup {
    position: absolute;
    top: 80px;
    height: calc(100% - 195px);
    background: none;

    @include mdq-max {
      top: 0;
      height: 100%;
    }

    .con {
      padding: 0 2px;
      width: 100%;

      @include mdq-max {
        width: 100%;
        height: 100%;
      }

      .btn-slide-prev,
      .btn-slide-next {
        width: 10px;
        height: 30px;
      }

      .btn-slide-prev {
        background: url(/imgs/common/swiper-prev.png) center/100% no-repeat;
      }

      .btn-slide-next {
        background: url(/imgs/common/swiper-next.png) center/100% no-repeat;
      }
    }
  }

  @include mdq-max() {
    width: 100%;
    height: 100%;
    padding: 0;

    &::before,
    &::after {
      display: none;
    }

    .status-bar {
      display: none;
    }

    &-content {
      height: 100%;
      padding: 20px;
      margin: 0;
    }

    &-inner {
      padding: 0;
      border-radius: 0;
    }

    &-footer {
      display: none;
      height: 100%;
    }
  }
}