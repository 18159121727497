.intro-card-swiper {
  .swiper-slide {
    height: 350px;

    @include mdq-max() {
      height: auto;
    }
  }
}

.intro-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  &-thumb {
    position: relative;
    overflow: hidden;
    flex: 0 1;
    padding-top: percentage(calc(756/972));

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      max-width: none;
      max-height: none;
      min-width: 1000%;
      min-height: 1000%;
      transform: translate(-50%, -50%) scale(0.1);
    }
  }
  
  &-desc {
    position: relative;
    padding: 20px;
    background-color: #fff;
    flex: 1 1;

    .icon-music {
      vertical-align: middle;
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 2px;
      margin-bottom: 2px;
    }

    .icon {
      width: 24px;
      height: 24px;
    }

    .field {
      color: #999;
      font-size: 15px;
      line-height: calc(18/15);

      mark {
        color: #999;
      }
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 17px;
      line-height: calc(28/26);
      font-weight: 700;

      @include mdq-max {
        font-size: 24px;
      }

      .icon {
        margin-left: 6px;
      }
    }

    .text {
      font-size: 13px;
      line-height: calc(18/13);
      letter-spacing: -0.02em;
      font-weight: 500;
      word-break: keep-all;
      margin-top: 10px;

      @include mdq-max {
        margin-top: 14px;
      }
    }
  }

  &.intro-card--2 {
    img {
      transform: translate(-50%, -51.2%) scale(0.1);
    }

    .title {
      margin-top: 3px;
    }
  }
}

.app-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 12px;
  margin-top: 20px;

  @include mdq-max() {
    margin-top: 24px;
  }

  .btn {
    position: relative;
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    padding: 0;
    margin: 0;
    background: none;
    border: 0;
    cursor: pointer;
    border-radius: 8px;

    .badge {
      position: absolute;
      top: 0;
      right: 0;
      width: 35px;
      transform: translate(70%, -40%);
    }
  }
}

.sns-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 16px;
  margin-top: 16px;

  @include mdq-max {
    margin-top: 24px;
  }

  .link {
    flex: 0 0 auto;
    height: 16px;

    img {
      width: auto;
      height: 100%;
    }
  }

  .privacy-policy {
    display: none;
    font-size: 10px;
    font-weight: 700;
    color: #bfbfbf;

    mark {
      color: #bfbfbf;
    }

    @include mdq-max {
      display: block;
    }
  }
}