.video-container {
  position: relative;
  height: 100%;
  margin-left: -25px;
  margin-right: -25px;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include mdq-max {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    video {
      height: auto;
    }
  }
}

.video-buttons {
  position: absolute;
  bottom: 140px;
  right: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  @include mdq-max {
    bottom: 20px;
    right: 20px;
  }

  .btn {
    width: 38px;
    height: 38px;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}
